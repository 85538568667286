@import 'animations.scss';

$maxFieldWidth: 60px;
$gap: 2.5px;

.Pawn {
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.5));
}

.GameTable {
  display: flex;
  justify-content: center;
  max-height: 1024px;
  width: 100%;
  height: 100%;
}

.BoardContainer {
  position: relative;
  width: 100%;
}

.FieldCaptionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  aspect-ratio: 1 / 1;
  pointer-events: none;
}

.FieldCaption {
  width: 100%;
  max-width: 170px;
  min-height: 280px;
  aspect-ratio: 5 / 6;
  background: #14307c;
  border: 3px solid #4066cd;
  border-radius: 0.5rem;
  box-shadow: -2px 5px 20px 0px #00000059;

  &__head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    height: 50px;
    border-radius: 5px 5px 0 0;
    text-shadow: 0 1px 5px #000000;
  }

  &__details {
    display: flex;
    text-shadow: 0 1px 5px #000000;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    font-size: 11px;
    font-weight: 400;
  }
}

.EditingMenu {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 0 0.5rem 2rem 1.5rem;
  border: 1px solid #003181;
  background: rgba(1, 26, 98, 0.6196078431);
  border-radius: 5px;
}

.DialogBox {
  background: #011a629e;
  border: 1px solid #003181;
  border-radius: 5px;
  padding: .75rem 1rem;
  margin: 2rem 0.5rem 2rem 1.5rem;

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
  }

  &__desc {
    font-size: 0.875rem;
    padding: 0.5rem 0;
    font-weight: 500;
  }


  &__button {
    background-color: #006ab5;
    min-height: 34px;
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.15385;
    margin: 0.5rem 0 0.5rem 0;
    outline: none;
    padding: 8px 1rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;

    &:disabled {
      filter: grayscale(1) contrast(0.3);
      opacity: 0.7;
      cursor: default;
      pointer-events: none;
    }

    &--green {
      background: linear-gradient(to right, #358127 0%, #3bb224 100%);

      &:hover {
        background: linear-gradient(to right, #3a9329 0%, #41d026 100%);
      }

      &:active, &--active {
        background-color: #496C40FF !important;
        box-shadow: none;
      }
    }

    &--red {
      background: #b73333;

      &:hover {
        background-color: #d53d3d !important;
      }

      &:active, &--active {
        background-color: #9d7171 !important;
        box-shadow: none;
      }
    }

    &:hover {
      background-color: #07c;
    }

    &:focus {
      box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
    }

    &:active, &--active {
      background-color: #6285a6 !important;
      box-shadow: none;
    }
  }
}

.Lose {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 5px);
  border-radius: 0 5px 5px 0;
  height: 100%;
  color: #a15151;
  font-weight: 700;
  top: 0;
  left: 5px;
  background: #3d1212bd;
}

.GamePlayer {
  display: flex;
  position: relative;
  width: calc(50% - .5rem);
  min-width: 170px;
  padding: 0.5rem;
  background: #011a62;
  border: 1px solid #003181;
  border-radius: 5px;
  margin-bottom: .5rem;
  margin-right: .5rem;

  &.active {
    outline: 1px solid #0066ff;
    background: #003585;
  }

  &__balances {
    display: flex;
    position: relative;
    gap: .5rem;
  }

  &__name {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  &__saldo {
    position: absolute;
    font-weight: 600;
    @include fadeOutUp(3s, ease-in-out, forwards);

    &--red {
      color: #ff5353;
    }

    &--green {
      color: #60ee35;
    }
  }

  &__admin {

    img {
      width: 16px;
    }
  }

  &__empty {
    font-weight: 700 !important;
    color: #ffffff1c;
  }

  &__color {
    width: 5px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px 0 0 5px;
  }
}


.GamePlayer__avatar {
  width: 48px;
  height: 48px;

  img {
    width: 48px;
    height: 48px;
  }
}

.GamePlayers {
  display: flex;
  flex-wrap: wrap;
}

.GamePlayer__details {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 0.825rem;
}

.GamePawn {
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
  }
}

.GameActions {
  display: flex;
  gap: .5rem;
}


