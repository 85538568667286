@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  33% {
    opacity: 1;
    transform: translateY(0);
  }


  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}

@mixin fadeOutUp($duration: 2s, $easing: ease-in-out, $fill-mode: forwards) {
  animation: fadeOutUp $duration $easing $fill-mode;
}