@import 'vars.scss';
@import "fonts.scss";
@import 'Board.scss';

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background: url('../images/splash.png') no-repeat;
  background-size: cover;
  overflow: hidden;
}

div, p {
  box-sizing: border-box;
}

.App-logo {
  max-width: 300px;
  width: 100%;

  img {
    width: 100%;
    max-width: 100%;
  }
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  width: 100%;
  height: 100%;
  color: #FFF;
}

.Box {
  background: $bgMain;
  padding: 2rem;
  border-radius: 1rem;
}

.Loading {
  border-radius: 1rem;
  img {
    width: 36px;
  }
}


.Button {
  background: #358127; /* Old browsers */
  background: -moz-linear-gradient(left,  #358127 0%, #3bb224 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  #358127 0%,#3bb224 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  #358127 0%,#3bb224 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  border-radius: 50px;
  border: 0;
  padding: 7px 25px;
  min-height: 38px;
  font-size: 1rem;
  color: #FFF;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  cursor: pointer;

  &.danger {
    background: #358127; /* Old browsers */
    background: -moz-linear-gradient(left,  #bf3b3b 0%, #820808 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #bf3b3b 0%, #820808 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #bf3b3b 0%, #820808 100%);
  }

  &:disabled {
    opacity: 0.8;
    filter: grayscale(1);
    pointer-events: none;
    cursor: default;
    user-select: none;
  }
}

.Button:hover {
  filter: brightness(110%);
}

.Button:active {
  outline: 0;
}

.Button:focus {
  outline: 0;
}

.Modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.ModalBody {
  background: $bgMain;
  padding: 2rem;
  border-radius: 1rem;
}

.Modal__title {
  text-align: center;
  font-weight: 600;
  margin-bottom: 1rem;
}

.Modal__desc {
  font-weight: 400;
  font-size: 0.825rem;
}

.Modal__actions {
  display: flex;
  justify-content: center;
}


.Modal__button  {
  background: #358127; /* Old browsers */
  background: -moz-linear-gradient(top,  #163c79 0%, #224e95 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #163c79 0%,#224e95 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #224e95 0%, #163c79 100%);
  border-radius: 4px;
  border: 0;
  padding: 7px 25px;
  min-height: 38px;
  font-size: 0.825rem;
  color: #FFF;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  cursor: pointer;
  margin: 0.25rem;

  &:hover {
    filter: brightness(110%);
  }

  &:active {
    transform: scale(0.96);
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.8;
    filter: grayscale(1);
    pointer-events: none;
    cursor: default;
    user-select: none;
  }
}

